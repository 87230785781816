import React from "react";
import {
  SubTitle,
  Spacer,
  Progress,
  Button,
  Body,
  VestaboardContextProvider,
  useVestaboardSubscriptionConfigInformation,
  ActionButton,
  Modal,
  BoardPreview,
  BoardGallery,
  IBoard,
  Divider,
  Medium,
  Times,
  MultiTimeSelect,
  ToastProvider,
  Toast,
  Small,
} from "@vestaboard/installables";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import usePrevious from "./hooks/usePrevious";

function App() {
  return (
    <VestaboardContextProvider>
      <ToastProvider>
        <Router>
          <Routes>
            <Route path="/" element={<DefaultConfig />} />
            <Route path="/settings" element={<DefaultConfig />} />
          </Routes>
        </Router>
      </ToastProvider>
    </VestaboardContextProvider>
  );
}

const BoardPreviews: IBoard[] = [
  [
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [4, 15, 0, 25, 15, 21, 0, 11, 14, 15, 23, 0, 20, 8, 9, 19, 0, 23, 15, 18,4, 60],
    [67, 63, 67, 63, 67, 63, 67, 63, 67, 63, 67, 63, 67, 63, 67, 63, 67, 63, 67, 63, 67, 63],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 1, 13, 2, 9, 7, 21, 15, 21, 19, 0, 41, 1, 56, 42, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  ],
  [
    [67, 63, 0, 0, 1, 13, 2, 9, 7, 21, 15, 21, 19, 0, 41, 1, 56, 42, 0, 0, 67, 63],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 21, 14, 3, 12, 5, 1, 18, 0, 15, 6, 0, 9, 14, 5, 24, 1, 3, 20, 0, 0, 0],
    [0, 2, 5, 3, 1, 21, 19, 5, 0, 1, 0, 3, 8, 15, 9, 3, 5, 0, 0, 0, 0, 0],
    [0, 1, 12, 20, 5, 18, 14, 1, 20, 9, 22, 5, 19, 0, 8, 1, 19, 0, 0, 0, 0, 0],
    [0, 14, 15, 20, 0, 2, 5, 5, 14, 0, 13, 1, 4, 5, 56, 0, 0, 0, 0, 0, 0, 0],
    
  ],
];


const DefaultConfig = () => {
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [sendNowSuccessOpen, setSendNowSuccessOpen] = React.useState(false);
  const [failureOpen, setFailureOpen] = React.useState(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [sendNowLoading, setSendNowLoading] = React.useState(false);
  const { data, loading } = useVestaboardSubscriptionConfigInformation();

  const [times, setTimes] = React.useState<Times[]>([]);
  let prevTimes = usePrevious<Times[]>(times);

  function handlePreview() {
    setPreviewOpen(true);
  }

  function handleSubmit() {
    setSubmitLoading(true);
    const jsonData = {
      times: times,
      subscription: data,
    };

    fetch(`${window.location.origin.toString()}/update`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(jsonData),
    })
      .then((response) => response.json())
      .then((data) => {
        setSubmitLoading(false);
        setSuccessOpen(true);
      })
      .catch((error) => {
        console.log(error);
        setSubmitLoading(false);
        setFailureOpen(true);
      });
  }

  function handleSendNow() {
    setSendNowLoading(true);
    const jsonData = {
      subscription: data,
    };

    fetch(`${window.location.origin.toString()}/send-now`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(jsonData),
    })
      .then((response) => response.json())
      .then((_) => {
        setSendNowLoading(false);
        setSendNowSuccessOpen(true);
      })
      .catch((error) => {
        console.log(error);
        setSendNowLoading(false);
        setFailureOpen(true);
      });
  }

  React.useEffect(() => {
    if (!data?.subscription?.id) return;
    fetch(
      `${window.location.origin.toString()}/get-times?subscriptionID=${
        data?.subscription?.id
      }&timezone=${data?.subscription?.board[0]?.timezone?.city}`
    )
      .then((response) => response.json())
      .then((data) => {
        setTimes(
          data?.data?.times.map((time: any, idx: any) => {
            // for some reason, the vestaboard components only allow two digit numbers as times
            if (time.hour.length === 1) time.hour = `0${time.hour}`;
            if (time.minutes.length === 1) time.minutes = `0${time.minutes}`;
            return {
              index: idx,
              label: `Time ${idx + 1}`,
              time,
            };
          })
        );
      })
      .catch(() => {});
  }, [data]);

  React.useEffect(() => {
    const deleteTime = (hour: string, minutes: string, amPm: string) => {
      fetch(`${window.location.origin.toString()}/delete-time`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          hour: hour,
          minutes: minutes,
          amPm: amPm,
          subscriptionID: data?.subscription?.id,
          timezone: data?.subscription.board[0].timezone.city,
        }),
      })
        .then((response) => response.json())
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    if (!times || !prevTimes) return;
    // compare the old times array to the new one
    // if the new array has fewer elements, then a time was deleted
    // therefore, we can loop through compare them to find the deleted element
    if (times.length < prevTimes.length) {
      for (const prevTime of prevTimes) {
        let exists = false;

        // perform a linear search to find which time was deleted
        for (const time of times) {
          if (
            prevTime.time.hour === time.time.hour &&
            prevTime.time.minutes === time.time.minutes &&
            prevTime.time.amPm === time.time.amPm
          ) {
            exists = true;
            break;
          }
        }

        if (!exists) {
          deleteTime(
            prevTime.time.hour,
            prevTime.time.minutes,
            prevTime.time.amPm
          );
          break;
        }
      }
    }

    return () => {
      prevTimes = times;
    };
  }, [times]);

  if (loading) {
    return (
      <div className="settings">
        <Progress />
      </div>
    );
  } else if (!data) {
    return (
      <div className="settings">
        <SubTitle>Please Wait...</SubTitle>
        <Body>Your subscription config token cannot be found.</Body>
      </div>
    );
  } else if (!data?.subscription?.board[0]?.timezone) {
    return (
      <div>
        <SubTitle>Oops, there's a problem.</SubTitle>
        <Body>
          Please navigate to the settings page to set your timezone to continue.
        </Body>
      </div>
    );
  }

  return (
    <div className="settings">
      <SubTitle>Description</SubTitle>
      <Spacer size="large" />
      <Body>
        Quiz your own vocabulary with words taken from the SAT word list!
      </Body>
      <Spacer size="large" />
      <ActionButton
        buttonType="default"
        type="button"
        onClick={() => handlePreview()}
      >
        Preview
      </ActionButton>
      <Spacer size="large" />
      <Modal
        visible={previewOpen}
        transparent={true}
        fullScreenMobile={true}
        onClose={() => setPreviewOpen(false)}
      >
        <BoardPreview characters={BoardPreviews[0]} />
        <Spacer size="large" />
        <BoardPreview characters={BoardPreviews[1]} />
      </Modal>
      <Divider />
      <Spacer size="large" />
      <div>
        <SubTitle>Send Now</SubTitle>
        <Spacer size="small" />
        <Small>Try out this installable by sending it to your board now!</Small>
        <Spacer size="large" />
        {!sendNowLoading ? (
          <Button onClick={() => handleSendNow()} buttonType="white">
            Send Now
          </Button>
        ) : (
          <div
            style={{
              background: "transparent",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Progress />
          </div>
        )}
        <Spacer size="large" />
        <Divider />
        <Spacer size="large" />
      </div>
      <div>
        <SubTitle>Automatic Send</SubTitle>
        <Spacer size="large" />
        <Medium>
          Automatically send new SAT vocab quizzes to your board at the
          specified times each day.
        </Medium>
        <Spacer size="large" />
        <MultiTimeSelect onChange={setTimes} maxTimes={10} value={times} />
        <Small>
          Your timezone is set to{" "}
          {data?.subscription?.board[0]?.timezone?.city.replaceAll("_", " ")}.
          If this is incorrect, adjust your timezone in Settings.
        </Small>
      </div>
      <Spacer size="large" />
      <Divider />
      <Spacer size="large" />
      {!submitLoading ? (
        <Button onClick={() => handleSubmit()} buttonType="white">
          Save Changes
        </Button>
      ) : (
        <div
          style={{
            background: "transparent",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Progress />
        </div>
      )}

      <Toast
        severity="success"
        message="Your settings were saved successfully!"
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
      />
      <Toast
        severity="success"
        message="Sent a vocab quiz successfully!"
        open={sendNowSuccessOpen}
        onClose={() => setSendNowSuccessOpen(false)}
      />
      <Toast
        severity="error"
        message="Oops, something went wrong..."
        open={failureOpen}
        onClose={() => setFailureOpen(false)}
      />
    </div>
  );
};

export default App;
